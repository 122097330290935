.navbar-center .nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  height: 100%;
  margin-right: 1.5rem;
}

.navbar-center .nav-links span {
  transition: opacity 0.2s ease-in;
  text-decoration: none;
}
.menuItem {
  font-size: 18px;
  font-weight: 300;
  color: var(--menu-white);
  transition: opacity 0.2s ease-in;
}
.menuItem:hover {
  opacity: 0.5;
}
