@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #215a75;
  --dark-color: #174052;
  --light-color: #90adba;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --mid-color: #4d7b91;
  --background: #dddddd;
  --shadow: #eeeeee;
  --hover: #7a94a0;
  --chat-own: #3d6f86;
  --chat-party: #c8d6dc;
  --menu-white: #ffff;
  --official-color: #4d7b91;
}
::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "Inter", "Roboto", "sans-serif";
  color: #333;
}

::-moz-placeholder {
  font-size: 13px;
  font-family: "Inter", "Roboto", "sans-serif";
  color: #333;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smooth_loading {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7903536414565826) 46%,
    rgba(77, 123, 145, 1) 100%
  );
}

.centerContents {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.body {
  display: flex;
  height: 100vh;

  background-color: #215a75;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  flex-direction: column;
}
.footer1 {
  font-size: 24;
  font-weight: 200;
  color: #fff;
}

/* 📱 Mobile Devices: Portrait Mode (up to 767px wide) */
@media screen and (max-width: 767px) and (orientation: portrait) {
  body {
    font-size: 14px;
    padding: 10px;
  }
  .footer1 {
    font-size: 10px;
    font-weight: 300;
    color: #fff;
  }
}

/* 📱 Mobile Devices: Landscape Mode (up to 1024px wide) */
@media screen and (max-width: 1024px) and (orientation: landscape) {
  body {
    font-size: 16px;
    padding: 15px;
  }
  .footer1 {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
  }
}
