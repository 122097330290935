.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 60px;
}

.navbar-center .nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  height: 100%;
  margin-right: 1rem;
}

.navbar-center .nav-links span {
  transition: opacity 0.2s ease-in;
  text-decoration: none;
}
.menuItem {
  font-size: 25px;
  font-weight: 600;
}
